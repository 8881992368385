import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import DiscordIcon from "@/components/icons/DiscordIcon";
import BlueskyIcon from "@/components/icons/BlueskyIcon.vue";
import XIcon from "@/components/icons/XIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            discord: {
                component: DiscordIcon,
            },
            bluesky: {
                component: BlueskyIcon,
            },
            x: {
                component: XIcon,
            }
        }
    }
});
